import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BackgroundIcon, MaskIcon } from '../../../components/Icons/Icons';
import { COLORS } from '../../../constants/colors';
import { useAppSelector } from '../../../redux/hooks';

import { useLocale } from '../../../contexts/LocalizationContext';
import { useNotificationsContext } from '../UserLayout/UserLayout';
import { ManagerStoreNavComp } from './ManagerStoreNavComp';
import {
  BetaTileContainer,
  NavigationItem,
  NavigationTile,
  NavigationTileAction,
  NavigationTileHeader,
  NavigationTileTitle,
  NewTileContainer,
  NoticePill,
  SideNavigationContainer,
  SideNavigationWrapper,
  SubOption,
  SubOptionsContainer,
} from './SideNavvigationStyles';

export interface ManagerSubOptionI {
  show: boolean;
  id: number;
}

function SideNavigation() {
  const router = useRouter();
  const notificationsContext = useNotificationsContext();
  const { user } = useAppSelector((state) => state.user);
  const [selectedHeaderOption, setSelectedHeaderOption] = useState<
    'user' | 'seller' | 'setting' | 'accounts' | ''
  >('');
  const [showProfileSubOption, setShowProfileSubOption] = useState(false);
  const [showStoreSubOption, setShowStoreSubOption] = useState(false);
  const [showManagerSubOption, setShowManagerSubOption] =
    useState<ManagerSubOptionI>({ show: false, id: 0 });
  const [userType, setUserType] = useState<string>('admin');
  const [showSellerSettingsSubOption, setShowSellerSettingsSubOption] =
    useState(false);

  const userData = useAppSelector((state) => state.user);

  const { messages } = useLocale();
  const {
    my_profile_msg,
    personal_details_msg,
    contact_details_msg,
    password_msg,
    Two_fa_msg,
    my_library_msg,
    my_orders_msg,
  } = messages || {};

  const handleProfileSubOptionClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setShowProfileSubOption(!showProfileSubOption);
      setShowStoreSubOption(false);
      setShowSellerSettingsSubOption(false);
    },
    [showProfileSubOption]
  );

  const handleStoreSubOptionClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setShowStoreSubOption(!showStoreSubOption);
      setShowProfileSubOption(false);
      setShowSellerSettingsSubOption(false);
    },
    [showStoreSubOption]
  );

  const handleSellerSettingsSubOptionClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setShowSellerSettingsSubOption(!showSellerSettingsSubOption);
      setShowProfileSubOption(false);
      setShowStoreSubOption(false);
    },
    [showSellerSettingsSubOption]
  );

  const handleManagerSubOptionClick = useCallback(
    (e: React.MouseEvent, storeId: number) => {
      e.stopPropagation();
      e.preventDefault();
      setShowManagerSubOption((prev) => ({
        show: !prev.show,
        id: storeId,
      }));
    },
    [showManagerSubOption]
  );

  useEffect(() => {
    let showProfile = false;
    let showStore = false;
    let showSellerSettings = false;
    let showManagerAccount = false;
    let selectedOption: 'user' | 'seller' | 'setting' | 'accounts' | '' = '';

    switch (true) {
      case router.pathname.includes('profile'):
        showProfile = true;
        selectedOption = 'user';
        break;
      case router.pathname.includes('selling-accounts'):
        showManagerAccount = true;
        selectedOption = 'seller';
        break;
      case router.pathname.includes('api-settings'):
      case router.pathname.includes('declared-stock'):
      case router.pathname.includes('surcharge'):
      case router.pathname.includes('notification-settings'):
      case router.pathname.includes('campaign-settings'):
        showSellerSettings = true;
        showStore = false;
        showManagerAccount = false;
        selectedOption = 'setting';
        break;
      case router.pathname.includes('notifications'):
        showStore = true;
        showManagerAccount = false;
        selectedOption = 'seller';
        break;
      case router.pathname.includes('selling'):
        showStore = true;
        showManagerAccount = false;
        selectedOption = 'seller';
        break;
      default:
        break;
    }
    setSelectedHeaderOption(selectedOption);
    setShowProfileSubOption(showProfile);
    setShowStoreSubOption(showStore);
    setShowSellerSettingsSubOption(showSellerSettings);
    setShowManagerSubOption({ show: showManagerAccount, id: 0 });
  }, [router.pathname]);

  const storeUser = useMemo(() => {
    if (!user) return null;

    if (user.agentStoreDetails?.store_details?.length > 0 && user?.store_id) {
      return 'both';
    }
    if (user.store_id) {
      return 'admin';
    }
    if (user.agentStoreDetails?.cust_id) {
      return 'agent';
    }
  }, [user]);

  return (
    <SideNavigationWrapper>
      <SideNavigationContainer>
        <NavigationItem>
          <Link href={'/user/profile'}>
            <a>
              <NavigationTile
                active={selectedHeaderOption === 'user' || showProfileSubOption}
              >
                <NavigationTileHeader>
                  <MaskIcon
                    url="/icons/face-24.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    color={COLORS.white}
                  />

                  <NavigationTileTitle>{my_profile_msg}</NavigationTileTitle>
                </NavigationTileHeader>
                <NavigationTileAction>
                  <MaskIcon
                    url="/icons/arrow-down-chevron-24.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    margin={'0 0 0 auto'}
                    color={COLORS.white}
                    onClick={handleProfileSubOptionClick}
                    style={{
                      zIndex: 10,
                      transform: showProfileSubOption
                        ? 'rotate(180deg)'
                        : 'rotate(0)',
                      transition: 'transform 0.3s ease-in-out',
                    }}
                  />
                </NavigationTileAction>
              </NavigationTile>
            </a>
          </Link>
          {showProfileSubOption && (
            <SubOptionsContainer>
              <Link href={'/user/profile'} prefetch={false}>
                <a>
                  <SubOption active={router.pathname === '/user/profile'}>
                    {personal_details_msg}
                  </SubOption>
                </a>
              </Link>
              <Link href={'/user/profile/contact'} prefetch={false}>
                <a>
                  <SubOption
                    active={router.pathname === '/user/profile/contact'}
                  >
                    {contact_details_msg}
                  </SubOption>
                </a>
              </Link>
              <Link href={'/user/profile/password'} prefetch={false}>
                <a>
                  <SubOption
                    active={router.pathname === '/user/profile/password'}
                  >
                    {password_msg}
                  </SubOption>
                </a>
              </Link>
              <Link href={'/user/profile/twoFA'} prefetch={false}>
                <a>
                  <SubOption active={router.pathname === '/user/profile/twoFA'}>
                    {Two_fa_msg}
                  </SubOption>
                </a>
              </Link>
            </SubOptionsContainer>
          )}
        </NavigationItem>

        <NavigationItem>
          <Link href={'/user/library'}>
            <a>
              <NavigationTile active={router.pathname.includes('library')}>
                <NavigationTileHeader>
                  <MaskIcon
                    url="/icons/controller-24.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    color={COLORS.white}
                  />
                  <NavigationTileTitle>{my_library_msg}</NavigationTileTitle>
                </NavigationTileHeader>
              </NavigationTile>
            </a>
          </Link>
        </NavigationItem>
        <NavigationItem>
          <Link href={'/user/orders'}>
            <a>
              <NavigationTile active={router.pathname.includes('orders')}>
                <NavigationTileHeader>
                  <MaskIcon
                    url="/icons/cart-24.svg"
                    width="24px"
                    height="24px"
                    selected={true}
                    color={COLORS.white}
                  />

                  <NavigationTileTitle>{my_orders_msg}</NavigationTileTitle>
                </NavigationTileHeader>
              </NavigationTile>
            </a>
          </Link>
        </NavigationItem>
        {userData.subscription === 'no' ||
        user?.storeDetails?.store_name ? null : (
          <NavigationItem>
            <Link href={'/user/membership'}>
              <a>
                <NavigationTile active={router.pathname.includes('member')}>
                  <NavigationTileHeader>
                    <BackgroundIcon
                      url={'/logo/driffle-plus-icon-24.svg'}
                      width="24px"
                      height="24px"
                      margin="0 6px 0 0"
                    />
                    <NavigationTileTitle>Driffle plus</NavigationTileTitle>
                  </NavigationTileHeader>
                </NavigationTile>
              </a>
            </Link>
          </NavigationItem>
        )}
        {user?.store_id && (
          <NavigationItem>
            <Link href={`${'/user/selling/dashboard'}`}>
              <a>
                <NavigationTile
                  active={
                    selectedHeaderOption === 'seller' || showStoreSubOption
                  }
                >
                  <NavigationTileHeader>
                    <MaskIcon
                      url="/icons/store.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      color={COLORS.white}
                    />

                    <NavigationTileTitle>My Store</NavigationTileTitle>
                  </NavigationTileHeader>
                  <NavigationTileAction>
                    {!isNaN(
                      Number(notificationsContext?.unreadNotificationsCount)
                    ) &&
                      notificationsContext!.unreadNotificationsCount > 0 &&
                      user?.store_id && (
                        <NoticePill
                          style={{
                            width: '8px',
                            height: '8px',
                            minHeight: '8px',
                            minWidth: '8px',
                            borderRadius: '50px',
                            padding: 0,
                          }}
                        />
                      )}
                    <MaskIcon
                      url="/icons/arrow-down-chevron-24.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      margin={'0 0 0 auto'}
                      color={COLORS.white}
                      onClick={(e: React.MouseEvent) =>
                        handleStoreSubOptionClick(e)
                      }
                      style={{
                        zIndex: 10,
                        transform: showStoreSubOption
                          ? 'rotate(180deg)'
                          : 'rotate(0)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  </NavigationTileAction>
                </NavigationTile>
              </a>
            </Link>
            {showStoreSubOption && (
              <SubOptionsContainer>
                <Link href={'/user/selling/dashboard'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/dashboard'}
                    >
                      Dashboard
                    </SubOption>
                  </a>
                </Link>
                <Link href={'/user/selling/currently-selling'} prefetch={false}>
                  <a>
                    <SubOption
                      active={
                        router.pathname === '/user/selling/currently-selling'
                      }
                    >
                      Currently Selling
                    </SubOption>
                  </a>
                </Link>
                <Link href={'/user/selling/transactions'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/transactions'}
                    >
                      Transactions
                    </SubOption>
                  </a>
                </Link>
                {user?.storeDetails?.disabled === 1 ? null : (
                  <Link href={'/user/selling/sell-item'} prefetch={false}>
                    <a>
                      <SubOption
                        active={router.pathname === '/user/selling/sell-item'}
                      >
                        Sell Item
                      </SubOption>
                    </a>
                  </Link>
                )}

                {!!user?.storeDetails?.features_allowed?.campaigns && (
                  <Link href={'/user/selling/campaigns'} prefetch={false}>
                    <a>
                      <SubOption
                        active={router.pathname === '/user/selling/campaigns'}
                      >
                        Campaigns <NewTileContainer>New</NewTileContainer>
                      </SubOption>
                    </a>
                  </Link>
                )}
                <Link href={'/user/selling/withdrawals'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/withdrawals'}
                    >
                      Withdrawals
                    </SubOption>
                  </a>
                </Link>
                <Link href={'/user/selling/reports'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/reports'}
                    >
                      Reports
                    </SubOption>
                  </a>
                </Link>

                <Link href={'/user/selling/tickets'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname.includes('/user/selling/tickets')}
                    >
                      Tickets <BetaTileContainer>Beta</BetaTileContainer>{' '}
                      {!isNaN(
                        Number(notificationsContext?.unResolvedTicketsCount)
                      ) &&
                        notificationsContext!.unResolvedTicketsCount > 0 && (
                          <NoticePill>
                            {notificationsContext!.unResolvedTicketsCount}
                          </NoticePill>
                        )}
                    </SubOption>
                  </a>
                </Link>
                {!!storeUser && storeUser === 'admin' && (
                  <Link href={'/user/selling/ticket-logs'} prefetch={false}>
                    <a>
                      <SubOption
                        active={router.pathname.includes(
                          '/user/selling/ticket-logs'
                        )}
                      >
                        Ticket Logs <BetaTileContainer>Beta</BetaTileContainer>{' '}
                      </SubOption>
                    </a>
                  </Link>
                )}

                <Link href={'/user/selling/ratings'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/ratings'}
                    >
                      Ratings & Reviews
                    </SubOption>
                  </a>
                </Link>
                <Link href={'/user/selling/notifications'} prefetch={false}>
                  <a>
                    <SubOption
                      active={router.pathname === '/user/selling/notifications'}
                    >
                      Inbox{' '}
                      {!isNaN(
                        Number(notificationsContext?.unreadNotificationsCount)
                      ) &&
                        notificationsContext!.unreadNotificationsCount > 0 && (
                          <NoticePill>
                            {notificationsContext!.unreadNotificationsCount}
                          </NoticePill>
                        )}
                    </SubOption>
                  </a>
                </Link>
              </SubOptionsContainer>
            )}
          </NavigationItem>
        )}
        {user?.store_id && (
          <NavigationItem>
            <Link
              href={
                user.storeDetails?.features_allowed?.apiSelling
                  ? '/user/selling/api-settings'
                  : '/user/selling/notification-settings'
              }
            >
              <a>
                <NavigationTile
                  active={
                    selectedHeaderOption === 'setting' ||
                    showSellerSettingsSubOption
                  }
                >
                  <NavigationTileHeader>
                    <MaskIcon
                      url="/icons/settings.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      color={COLORS.white}
                    />

                    <NavigationTileTitle>Store Settings</NavigationTileTitle>
                  </NavigationTileHeader>
                  <NavigationTileAction>
                    <MaskIcon
                      url="/icons/arrow-down-chevron-24.svg"
                      width="24px"
                      height="24px"
                      selected={true}
                      margin={'0 0 0 auto'}
                      color={COLORS.white}
                      onClick={handleSellerSettingsSubOptionClick}
                      style={{
                        zIndex: 10,
                        transform: showSellerSettingsSubOption
                          ? 'rotate(180deg)'
                          : 'rotate(0)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  </NavigationTileAction>
                </NavigationTile>
              </a>
            </Link>
            {showSellerSettingsSubOption && (
              <SubOptionsContainer>
                {user.storeDetails?.features_allowed?.apiSelling && (
                  <Link href={'/user/selling/api-settings'} prefetch={false}>
                    <a>
                      <SubOption
                        active={
                          router.pathname === '/user/selling/api-settings'
                        }
                      >
                        API Settings
                      </SubOption>
                    </a>
                  </Link>
                )}
                {!!user.storeDetails?.features_allowed?.declaredStock && (
                  <Link href={'/user/selling/declared-stock'} prefetch={false}>
                    <a>
                      <SubOption
                        active={
                          router.pathname === '/user/selling/declared-stock'
                        }
                      >
                        Declared Stock
                      </SubOption>
                    </a>
                  </Link>
                )}
                {!!user.storeDetails?.features_allowed?.vat && (
                  <Link href={'/user/selling/surcharge'} prefetch={false}>
                    <a>
                      <SubOption
                        active={router.pathname === '/user/selling/surcharge'}
                      >
                        Surcharge Settings
                      </SubOption>
                    </a>
                  </Link>
                )}
                {!!user.storeDetails?.features_allowed?.campaigns && (
                  <Link
                    href={'/user/selling/campaign-settings'}
                    prefetch={false}
                  >
                    <a>
                      <SubOption
                        active={
                          router.pathname === '/user/selling/campaign-settings'
                        }
                      >
                        Campaign Settings
                      </SubOption>
                    </a>
                  </Link>
                )}
                <Link
                  href={'/user/selling/notification-settings'}
                  prefetch={false}
                >
                  <a>
                    <SubOption
                      active={
                        router.pathname ===
                        '/user/selling/notification-settings'
                      }
                    >
                      Notification Settings
                    </SubOption>
                  </a>
                </Link>
              </SubOptionsContainer>
            )}
          </NavigationItem>
        )}
        {user &&
          user.agentStoreDetails?.store_details
            ?.slice(0, 1)
            .map((store: any, id: number) => {
              return (
                <ManagerStoreNavComp
                  key={id}
                  store={store}
                  handleManagerSubOptionClick={handleManagerSubOptionClick}
                  selectedHeaderOption={selectedHeaderOption}
                  showManagerSubOption={showManagerSubOption}
                />
              );
            })}
      </SideNavigationContainer>
    </SideNavigationWrapper>
  );
}

export default SideNavigation;
