import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { NewNavbarInterface } from '../../Interface/NavbarInterface';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';
import { useLocale } from '../../contexts/LocalizationContext';
import { capitalizeFirstLetter } from '../../helper/helper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppSelector } from '../../redux/hooks';
import { saveEventV3 } from '../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { BackgroundIcon, MaskIcon } from '../Icons/Icons';
import OptionsSectionsV2 from './OptionsSectionV2';
import {
  NavbarOptionContainer,
  NavbarOverlay,
  OptionsItemChildrenContainer,
  OptionsSection,
  SubHeaderBg,
  SubHeaderContainer,
  SubHeaderContainerMenu,
  SubHeaderItem,
  SubHeaderItemMarker,
  SubHeaderWrapper,
} from './SubHeaderStyle';
import { SubOptionContainerI } from './modals';

const SubHeader = ({
  navItems,
  scrollDirection,
}: {
  navItems: NewNavbarInterface;
  scrollDirection: any;
}) => {
  const { width } = useWindowDimensions();
  const router = useRouter();
  const user = useAppSelector((state) => state.user);
  const { messages } = useLocale();
  const { membership_msg } = messages || {};

  const [subOptionContainer, setSubOptionContainer] =
    useState<SubOptionContainerI>({
      show: false,
      index: null,
      last: null,
    });

  const handleShowContainer = useCallback((index) => {
    setSubOptionContainer({
      show: true,
      index: index,
      last: index,
    });
  }, []);

  const handleCloseContainer = useCallback(() => {
    setSubOptionContainer((prev) => ({
      last: prev.index,
      show: false,
      index: null,
    }));
  }, []);

  useEffect(() => {
    const handleScroll = () => handleCloseContainer();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleCloseContainer]);

  const showSubMenuCount = useCallback(
    (data) => {
      if (width && width > getNumFromResolutionConstant(WIDTH.laptopMax))
        return 5;
      if (width && width > getNumFromResolutionConstant(WIDTH.tabletMax))
        return 3;
      return 0;
    },
    [width]
  );

  const renderSubMenuItems = useCallback(() => {
    return navItems?.children.map(
      (data, index) =>
        data.subDivision &&
        data.subDivision
          .slice(0, showSubMenuCount(data.subDivision))
          .map((sectionData, index2) => (
            <OptionsSectionsV2
              key={index2}
              data={sectionData}
              index={index}
              parentSubMenu={data.name}
              onClose={handleCloseContainer}
              isShowing={
                subOptionContainer.show && index === subOptionContainer.last
              }
              display={subOptionContainer.last}
            />
          ))
    );
  }, [navItems, showSubMenuCount, subOptionContainer, handleCloseContainer]);

  return (
    <SubHeaderWrapper
      style={{
        opacity: scrollDirection === 'down' ? 0 : 1,
        pointerEvents: scrollDirection === 'down' ? 'none' : 'inherit',
      }}
    >
      <SubHeaderBg />
      <SubHeaderContainer>
        <SubHeaderContainerMenu>
          {navItems &&
            navItems?.children
              ?.filter((child: any) => child.navbar)
              ?.map((item: any, index: number) => {
                return item.name === 'save with plus' &&
                  (user.subscription === 'no' ||
                    user?.user?.storeDetails?.store_name) ? null : (
                  <Link
                    key={index}
                    passHref
                    href={
                      item.name
                        ? item.name === 'store'
                          ? item.url
                          : item.url?.includes('?')
                          ? '/store/' + item.name
                          : item.url ?? ''
                        : ''
                    }
                    prefetch={false}
                  >
                    <a>
                      <SubHeaderItem
                        selected={index === subOptionContainer.index}
                        onClick={() => {
                          if (item.url) {
                            saveEventV3({
                              category: 'header',
                              action: 'click',
                              label: 'sub-header-option',
                              properties: item.displayName,
                              value: [item.url],
                              from: router,
                            });
                          }
                        }}
                        onMouseLeave={() =>
                          item.subDivision && handleCloseContainer()
                        }
                        onMouseEnter={() =>
                          item.subDivision && handleShowContainer(index)
                        }
                      >
                        {item.name === 'save with plus'
                          ? user.subscription === 'active'
                            ? ''
                            : capitalizeFirstLetter(
                                item?.displayName?.replace('[--PLUS--]', '')
                              )
                          : item.displayName}
                        {item.subDivision && (
                          <MaskIcon
                            url="/icons/arrow-down-bold.svg"
                            height="18px"
                            width="18px"
                            margin="0"
                            color={COLORS.white}
                            selected={true}
                          />
                        )}
                        {item.name === 'steal deals' && (
                          <BackgroundIcon
                            height="24px"
                            width="24px"
                            margin="0 0 4px 4px"
                            url="/gifs/hot-trending.gif"
                          />
                        )}
                        {item.name === 'save with plus' && (
                          <BackgroundIcon
                            height="24px"
                            width="36px"
                            margin="0 5px 0 5px"
                            url="/logo/plus-logo.svg"
                          />
                        )}
                        {item.name === 'save with plus' &&
                        user.subscription === 'active'
                          ? membership_msg
                          : null}
                        <SubHeaderItemMarker
                          selected={index === subOptionContainer.index}
                        />
                      </SubHeaderItem>
                    </a>
                  </Link>
                );
              })}
        </SubHeaderContainerMenu>
        {/* <SubMenuSmallStrip /> */}
      </SubHeaderContainer>
      <NavbarOptionContainer
        style={{
          display:
            subOptionContainer.show &&
            subOptionContainer.last !== null &&
            width &&
            width > getNumFromResolutionConstant(WIDTH.tabletMax)
              ? 'block'
              : 'none',
        }}
        index={subOptionContainer.last || 0}
        onMouseLeave={handleCloseContainer}
        onMouseEnter={() => handleShowContainer(subOptionContainer.last)}
      >
        <OptionsSection index={subOptionContainer.last || 0}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <OptionsItemChildrenContainer>
              {renderSubMenuItems()}
            </OptionsItemChildrenContainer>
          </div>
        </OptionsSection>
      </NavbarOptionContainer>
      <NavbarOverlay
        style={{
          display:
            subOptionContainer.show &&
            subOptionContainer.last !== null &&
            width &&
            width > getNumFromResolutionConstant(WIDTH.tabletMax)
              ? 'block'
              : 'none',
        }}
      />
    </SubHeaderWrapper>
  );
};

const MemodSubHeader = React.memo(SubHeader);
export default MemodSubHeader;
