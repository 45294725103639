import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAppSelector } from '../../redux/hooks';

import {
  BetaTileContainer,
  NewTileContainer,
  NoticePill,
} from '../../user/components/SideNavigation/SideNavvigationStyles';
import { useNotificationsContext } from '../../user/components/UserLayout/UserLayout';

import { useContext } from 'react';
import { AuthContext } from '../../user/context/AuthContext';
import { MaskIcon } from '../Icons/Icons';
import { ContentWrapper, DrawerOption } from './NavDrawerStyle';

const SMDrawerContent = [
  {
    icon: '/icons/new-ticket-icon.svg',
    title: 'Tickets',
    route: '/user/selling-accounts/tickets',
  },
  {
    icon: '/icons/new-ticket-icon.svg',
    title: 'Ticket Logs',
    route: '/user/selling-accounts/ticket-logs',
  },
];

const drawerContent = [
  {
    icon: '/icons/solid-dashboard.svg',
    title: 'Dashboard',
    route: '/user/selling/dashboard',
  },
  {
    icon: '/icons/solid-selling.svg',
    title: 'Currently Selling',
    route: '/user/selling/currently-selling',
  },
  {
    icon: '/icons/bag-24.svg',
    title: 'Sell Item',
    route: '/user/selling/sell-item',
  },
  {
    icon: '/icons/speed-24.svg',
    title: 'Campaigns',
    route: '/user/selling/campaigns',
  },
  {
    icon: '/icons/seller-txn.svg',
    title: 'Transactions',
    route: '/user/selling/transactions',
  },
  {
    icon: '/icons/solid-withdraw.svg',
    title: 'Withdrawals',
    route: '/user/selling/withdrawals',
  },
  {
    icon: '/icons/solid-reports.svg',
    title: 'Reports',
    route: '/user/selling/reports',
  },
  {
    icon: '/icons/reviews-24.svg',
    title: 'Ratings & Reviews',
    route: '/user/selling/ratings',
  },
  {
    icon: '/icons/api-settings.svg',
    title: 'API Settings',
    route: '/user/selling/api-settings',
  },
  {
    icon: '/icons/declared-stock-icon.svg',
    title: 'Declared Stock',
    route: '/user/selling/declared-stock',
  },
  {
    icon: '/icons/new-ticket-icon.svg',
    title: 'Tickets',
    route: '/user/selling/tickets',
  },
  {
    icon: '/icons/new-ticket-icon.svg',
    title: 'Ticket Logs',
    route: '/user/selling/ticket-logs',
  },
  {
    icon: '/icons/notification_setting_re.svg',
    title: 'Inbox',
    route: '/user/selling/notifications',
  },
  {
    icon: '/icons/setting-24.svg',
    title: 'Surcharge Settings',
    route: '/user/selling/surcharge',
  },
  {
    icon: '/icons/campaign-settings.svg',
    title: 'Campaigns Settings',
    route: '/user/selling/campaign-settings',
  },
  {
    icon: '/icons/notification_setting_re.svg',
    title: 'Notification Settings',
    route: '/user/selling/notification-settings',
  },
];

interface Props {
  onClose: () => void;
}

export default function SellerDrawerContent({ onClose }: Props) {
  const router = useRouter();
  const { user } = useAppSelector((state) => state);
  const { storeUser } = useContext(AuthContext);
  const notificationsContext = useNotificationsContext();

  return (
    <ContentWrapper fromMobNav>
      {(!user?.user?.agentStoreDetails ? drawerContent : SMDrawerContent)
        .filter((cur) => {
          if (cur.title === 'Ticket Logs') {
            if (!!storeUser && (storeUser === 'admin' || storeUser === 'agent'))
              return true;

            return false;
          }
          return true;
        })
        .filter((cur) => {
          if (cur.title === 'Surcharge Settings') {
            if (user.user?.storeDetails?.features_allowed?.vat) {
              return true;
            } else {
              return false;
            }
          } else if (cur.title === 'Campaigns') {
            if (user.user?.storeDetails?.features_allowed?.campaigns) {
              return true;
            } else {
              return false;
            }
          } else if (cur.title === 'Declared Stock') {
            if (user.user?.storeDetails?.features_allowed?.declaredStock) {
              return true;
            } else {
              return false;
            }
          } else if (cur.title === 'API Settings') {
            if (user.user?.storeDetails?.features_allowed?.apiSelling) {
              return true;
            } else {
              return false;
            }
          } else if (
            !!storeUser &&
            storeUser === 'agent' &&
            cur.title === 'Tickets'
          ) {
            if (
              user?.user?.agentStoreDetails?.store_details[0]?.permissions?.includes(
                'tickets'
              )
            ) {
              return true;
            } else return false;
          } else if (
            !!storeUser &&
            storeUser === 'agent' &&
            cur.title === 'Ticket Logs'
          ) {
            if (
              storeUser === 'agent' &&
              user?.user?.agentStoreDetails?.store_details[0]?.permissions?.includes(
                'ticket_logs'
              )
            ) {
              return true;
            } else return false;
          } else {
            return true;
          }
        })

        .map((item, index) => {
          return user?.user?.storeDetails?.disabled === 1 &&
            item.title === 'Sell Item' ? null : (
            <Link key={index} passHref href={item.route}>
              <a>
                <DrawerOption
                  active={router.pathname.includes(item.route)}
                  onClick={onClose}
                >
                  <MaskIcon
                    url={item.icon}
                    selected
                    margin="0"
                    height="24px"
                    width="24px"
                    color={
                      router.pathname.includes(item.route) ? '#fff' : '#808080'
                    }
                  />
                  <p>{item.title}</p>
                  {item.title === 'Campaigns' ? (
                    <NewTileContainer>New</NewTileContainer>
                  ) : item.title === 'Tickets' ? (
                    <BetaTileContainer>Beta</BetaTileContainer>
                  ) : null}

                  {item.route === '/user/selling/notifications' && (
                    <>
                      {!isNaN(
                        Number(notificationsContext?.unreadNotificationsCount)
                      ) &&
                        notificationsContext!.unreadNotificationsCount > 0 && (
                          <NoticePill>
                            {notificationsContext!.unreadNotificationsCount}
                          </NoticePill>
                        )}
                    </>
                  )}
                  {item.route === '/user/selling/tickets' && (
                    <>
                      {!isNaN(
                        Number(notificationsContext?.unResolvedTicketsCount)
                      ) &&
                        notificationsContext!.unResolvedTicketsCount > 0 && (
                          <NoticePill>
                            {notificationsContext!.unResolvedTicketsCount}
                          </NoticePill>
                        )}
                    </>
                  )}
                </DrawerOption>
              </a>
            </Link>
          );
        })}
    </ContentWrapper>
  );
}
